.location-section {
	height: 1038px;
	background: url('https://fromley.imgix.net/45th-place/downtown_boise_skyline_single_balloon.jpg?auto=format&fp-x=.25&fit=crop&h=1038&w=640') #3b405e center top no-repeat;
	background-size: cover;
	color: $white;
	text-align: center;
	padding-top: 50px;
	position: relative;
	@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
	only screen and (-o-min-device-pixel-ratio: 13/10),
	only screen and (min-resolution: 120dpi) {
		background-image: url('https://fromley.imgix.net/45th-place/downtown_boise_skyline_single_balloon.jpg?auto=format&fp-x=.25&fit=crop&h=1038&w=640&dpr=2&q=40');
	}
	&::after {
		content: '';
		width: 100%;
		height: 280px;
		background: linear-gradient(360deg, $off-white 0%, rgba(255, 255, 255, 0.00) 100%);
		position: absolute;
		bottom: 0;
		left: 0;
	}
	h2 {
		color: $white;
	}
	.inner {
		width: 100%;
		max-width: 920px;
	}
}

@include breakpoint(640px) {
	.location-section {
		background-image: url('https://fromley.imgix.net/45th-place/downtown_boise_skyline_single_balloon.jpg?auto=format&fp-x=.25&fit=crop&h=1038&w=1000');
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://fromley.imgix.net/45th-place/downtown_boise_skyline_single_balloon.jpg?auto=format&fp-x=.25&fit=crop&h=1038&w=1000&dpr=2&q=40');
		}
	}
}

@include breakpoint(1000px) {
	.location-section {
		background-image: url('https://fromley.imgix.net/45th-place/downtown_boise_skyline_single_balloon.jpg?auto=format&w=1600');
		font-size: rem-calc(18);
		padding-top: 100px;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url('https://fromley.imgix.net/45th-place/downtown_boise_skyline_single_balloon.jpg?auto=format&w=1600&dpr=2&q=40');
		}
	}
}