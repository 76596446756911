/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
$header-layout-breakpoint: 900px;

.page-header {
    background: $brand;
    padding: 30px 0;
    position: relative;
    z-index: 20;
    .inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }
    .logo {
        max-width: 152px;
        margin-right: 20px;
        a {
            display: block;
        }
    }
    .nav-toggle {
        width: 24px;
        cursor: pointer;
    }
    img {
        margin: 0;
    }
    .large-nav {
        display: none;
    }
}


@include breakpoint(900px) {
    .page-header {
        .inner {
            align-items: flex-end;
        }
        .nav-toggle {
            display: none;
        }
        .large-nav {
            display: block;
        }
        .logo {
            max-width: 200px;
        }
    }
    .offscreen-nav {
        display: none !important;
    }
}

@include breakpoint(1200px) {
    .page-header {
        .logo {
            max-width: 326px;
        }
    }
}