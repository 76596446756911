/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    background-color: $brand;
    font-size: rem-calc(16);
    font-weight: $main-normal;
    color: $white;
    padding: 50px 0 32px;
    a {
        color: $brand-accent;
        &:hover, &:focus {
            color: $white;
        }
    }
    .main-footer {
        h2 {
            font-size: rem-calc(18);
            color: $white;
            line-height: 1.5;
            text-transform: uppercase;
            margin-bottom: 6px;
        }
        .logo {
            margin-bottom: 50px;
            img {
                max-width: 152px;
                margin: 0;
            }
        }
        .contact {
            margin-bottom: 50px;
            p {
                margin-bottom: 0;
            }
        }
        .social {
            margin-bottom: 50px;
            img {
                margin: 0;
            }
        }
    }
    .copyright {
        p {
            margin-bottom: 0;
        }
        ul {
            margin: 0 0 30px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        li {
            padding-left: 0;
            margin-bottom: 6px;
            margin-right: 30px;
            list-style: none;
            &::before {
                display: none;
            }
        }
        a {
            color: $white;
            font-weight: $main-bold;
            text-decoration: none;
            &:hover {
                color: $brand-accent;
            }
        }
    }
}

@include breakpoint(700px) {
    .page-footer {
        .main-footer {
            margin-bottom: 100px;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            .logo {
                margin: 0 30px 0 0;
            }
            .contact {
                margin: 0 30px 0 0;
            }
            .social {
                margin-bottom: 0;
            }
        }
    }
}

@include breakpoint(850px) {
    .page-footer .main-footer {
        .logo {
            margin-right: 80px;
            img {
                max-width: 220px;
            }
        }
        .contact {
            margin-right: 80px;
        }
    }
}

@include breakpoint(1000px) {
    .page-footer {
        .copyright {
            .inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            ul {
                margin: 0;
            }
            li {
                margin-right: 60px;
            }
        }
        .main-footer .contact-details {
            display: flex;
            p:first-child {
                margin-right: 30px;
            }
        }
    }
}