.hero {
	min-height: 500px;
	background: url("https://fromley.imgix.net/45th-place/bg_45thplace_exterior_rendering.jpg?auto=format&w=480") no-repeat center top #383b3e;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	.hero-content {
		max-width: 430px;
		margin: 0 auto;
	}
	h1 {
		font-size: rem-calc(36);
		text-align: center;
		line-height: 1.11;
		text-shadow: 0 4px 23px #000;
		margin: 0;
	}
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.hero {
		background-image: url("https://fromley.imgix.net/45th-place/bg_45thplace_exterior_rendering.jpg?auto=format&dpr=2&q=40&w=480");
	}
}

@include breakpoint(560px) {
	.hero {
		background-image: url("https://fromley.imgix.net/45th-place/bg_45thplace_exterior_rendering.jpg?auto=format&w=960");
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url("https://fromley.imgix.net/45th-place/bg_45thplace_exterior_rendering.jpg?auto=format&dpr=2&q=40&w=960");
		}
	}
}

@include breakpoint(960px) {
	.hero {
		background-image: url("https://fromley.imgix.net/45th-place/bg_45thplace_exterior_rendering.jpg?auto=format&w=1600");
		min-height: 920px;
		background-position: center bottom;
		@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
		only screen and (-o-min-device-pixel-ratio: 13/10),
		only screen and (min-resolution: 120dpi) {
			background-image: url("https://fromley.imgix.net/45th-place/bg_45thplace_exterior_rendering.jpg?auto=format&dpr=2&q=40&w=1600");
		}

		.hero-content {
			max-width: 900px;
		}
		h1 {
		   font-size: rem-calc(80);
		   text-align: center;
		   line-height: 1;
		}
	}
}