/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

html {
	min-height: 100vh;
	scroll-behavior: smooth;
	overflow-x: hidden;
}
body {
	min-height: 100vh;
	background: $white;
	font-family: $main-font;
	position: relative;
	overflow-x: hidden;
	@include font-size(default);
	font-weight: $main-normal;
	color: $main-color;
	@include breakpoint(large) {
		@include font-size(default-desktop);
	}
}

#main {
	min-width: 0;
}

.section-wrapper {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
}

p {
	font-size: 1em;
	margin: 0 0 $base-spacing;
	&.large {
		@include font-size(large);
		margin-bottom: 18px;
	}
	&.small {
		@include font-size(small);
		margin-bottom: 8px;
	}
	&.normal {
		@include font-size(default);
		margin-bottom: 24px;
	}
}
a {
	color: $link-base;
	text-decoration: underline;
	&:hover,
	&:focus {
		color: $white;
		text-decoration: none;
	}
}

a.base-text {
	color: $main-color;
	text-decoration: none;
}

hr {
	margin: 50px 0;
}

.inner {
	@include xy-grid-container;
}

.narrow-container {
	max-width: 1130px;
	margin: 0 auto;
}

.extra-narrow-container {
	max-width: 900px;
	margin: 0 auto;
}

/* @end */