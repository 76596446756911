/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/
$box-shadow-min-width: 10px;
$box-shadow-max-width: 400px;

.button,
form input[type="submit"] {
	max-width: 400px;
	background: $brand-accent;
	font-weight: $main-normal;
	font-size: rem-calc(16);
	color: $white;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1.25;
	padding: 11px 20px;
	margin-bottom: $base-spacing;
	border: 1px solid $white;
	display: inline-block;
	transition: background-color .2s, color .2s;
	&:hover,
	&:focus {
		background: $brand;
		text-decoration: none;
		cursor: pointer;
	}
}

.button.small {
	font-size: rem-calc(14);
	padding: 10px 13px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 16px 23px;
}
.button.short {
	padding-top: 7px;
	padding-bottom: 9px;
}
.button.tiny {
	font-size: rem-calc(12);
	line-height: 1;
	padding: 6px;
}
