.unit-galleries {
	max-width: 100%;
	margin: 0 auto;
	.glide__slides {
		min-width: 0;
	}
	.glide__slide {
		margin-bottom: 12px;
		padding: 0 10px;
		img {
			margin-bottom: 20px;
			box-shadow: $box-shadow;
		}
	}
	.slide-caption {
		font-weight: $main-bold;
		color: $brand-accent;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 0;
	}
	.glide__arrows {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;
	}
	.glide__arrow {
		width: 40px;
		height: 40px;
		background: $brand-accent;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		flex-shrink: 0;
		transition: background-color .3s;
		&:hover, &:focus-visible {
			background-color: $brand;
		}
		img {
			margin: 0;
		}
		&.glide__arrow--left img {
			margin-right: 4px;
		}
		&.glide__arrow--right img {
			margin-left: 4px;
		}
	}
}

.image-caption-slider {
	.glide__slide {
		img {
			margin: 0;
		}
	}
	.slide-caption {
		width: 100%;
		min-height: 50px;
		color: $white;
		background-color: $brand;
		text-align: center;
		padding: 10px 20px;
		p {
			margin: 0;
		}
		button {
			cursor: pointer;
		}
	}
	.glide__arrows {
		margin-top: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 40px;
	}
	.glide__arrow {
		width: 40px;
		height: 40px;
		background: $brand-accent;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		flex-shrink: 0;
		transition: background-color .3s;
		&:hover, &:focus-visible {
			background-color: $brand;
		}
		img {
			margin: 0;
		}
		&.glide__arrow--left img {
			margin-right: 4px;
		}
		&.glide__arrow--right img {
			margin-left: 4px;
		}
	}
}