.unit-tabs {
	max-width: 550px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: 30px;
	.tab-list {
		button {
			width: 100%;
			font-size: rem-calc(18);
			font-weight: $main-bold;
			color: $brand-accent;
			padding: 10px;
			margin-bottom: 10px;
			text-align: left;
			cursor: pointer;
			transition: color .3s, background-color .3s;
			display: block;
			&.active,
			&:hover,
			&:focus-visible {
				background-color: $brand-accent;
				color: $white;
			}
		}
	}
	.tab-panels {
		max-width: 100%;
		min-width: 0;
		h3 {
			color: $brand;
			margin-bottom: 10px;
		}
		ul {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: 10px 20px;
			margin: 0 0 30px;
		}
		li {
			list-style: none;
			margin: 0;
			padding: 0;
			&::before {
				display: none;
			}
		}
	}
	.tab-panel {
		max-width: 100%;
		min-width: 0;
	}
}

@include breakpoint(800px) {
	.unit-tabs {
		max-width: 100%;
		flex-direction: row;
		align-items: flex-start;
		.tabs-info {
			flex-basis: 40%;
		}
		.tab-panels {
			flex-basis: 60%;
		}
	}
}